import {
    AiOutlineDashboard,
    AiOutlineHome,
    AiOutlineSetting
    } from 'react-icons/ai'

import { RiProductHuntLine} from 'react-icons/ri'    
import { FiHelpCircle} from 'react-icons/fi'    

export const Menus = [
    {
        key: 'dashboard',
        label: "Dashboard",
        path: '/admin',
        icon: <AiOutlineDashboard />
    },
    {
        key: 'about-us',
        label: "About Us",
        path: '/admin/about-us',
        icon: <AiOutlineHome />
    },
    {
        key: 'slider',
        label: "Sliders",
        path: '/sliders',
        icon: <RiProductHuntLine />
    },
    {
        key: 'cancellation-policy',
        label: "Cancellation Policy",
        path: '/cancellation-policy',
        icon: <RiProductHuntLine />
    },
    {
        key: 'details-us',
        label: "Details Us",
        path: '/details-us',
        icon: <RiProductHuntLine />
    },
    {
        key: 'iframe',
        label: "Iframe Map",
        path: '/iframe-map',
        icon: <RiProductHuntLine />
    },
    {
        key: 'iframe',
        label: "Iframe Map",
        path: '/iframe-map',
        icon: <RiProductHuntLine />
    },
    {
        key: 'service-category',
        label: "Services",
        path: '/service-category',
        icon: <RiProductHuntLine />
    },
    {
        key: 'members',
        label: "Team Members",
        path: '/members',
        icon: <RiProductHuntLine />
    },
    {
        key: 'gallery',
        label: "Gallery",
        path: '/gallary',
        icon: <RiProductHuntLine />
    },
    {
        key: 'own-a-studio',
        label: "Own A Studio",
        path: '/own-a-studio',
        icon: <RiProductHuntLine />
    },
   
]

// export const Settings = [
//     {
//         key: 'setting',
//         label: "Setting",
//         path: '/setting',
//         icon: <AiOutlineSetting />
//     },
//     {
//         key: 'help',
//         label: "Help & Support",
//         path: '/setting',
//         icon: <FiHelpCircle />
//     }
// ]