import React, { useEffect, useState } from 'react'

export function Footer(){

    const [showButton, setShowButton] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
          if (window.pageYOffset > 300) {
            setShowButton(true);
          } else {
            setShowButton(false);
          }
        });
      }, []);

      const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth' // for smoothly scrolling
        });
      };

    return(
        <div className="w-full bg-gray-300 h-[60px]">
            <div className="max-w-[1240px] mx-auto flex justify-between text-center text-black">
                <p className="justify-center text-center px-5 pt-5">Copyright &copy; 2023. All right reserved.</p>
                {/* { showButton && 
                    (<button className="justify-center text-center cursor-pointer px-5 pt-5 font-xl" onClick={scrollToTop}> Top</button>)
                } */}
                <button className="justify-center text-center cursor-pointer px-5 pt-5 font-xl" onClick={scrollToTop}>  &#8679; </button>
            </div>
        </div>
    )
}