import React, { useState } from 'react'

import {BsCaretLeftSquareFill} from 'react-icons/bs'
import {BsCaretRightSquareFill} from 'react-icons/bs'

import Img0 from '../../../image/ban1.jpg'
import Img1 from '../../../image/ban2.jpg'
import Img2 from '../../../image/ban3.jpg'
import Img3 from '../../../image/SE_12.jpg'
import Img4 from '../../../image/SE_13.jpg'

export function HomeSlider(){

    const imgs = [Img0, Img1, Img2, Img3, Img4];
    const [current, setCurrent] = useState(0)
    const length = imgs.length;

    const nextSlide = () => {
        setCurrent(current === length - 1 ? 0 : current + 1);
      };
    
      const prevSlide = () => {
        setCurrent(current === 0 ? length - 1 : current - 1);
      };
    
      if (!Array.isArray(imgs) || imgs.length <= 0) {
        return null;
      }

    return(
        <>
             <div className="w-full md:w-auto  pb-5 relative flex justify-center items-center">
                <BsCaretLeftSquareFill className="absolute top=[-50%] text-white text-3xl cursor-pointer left-5" onClick={prevSlide}></BsCaretLeftSquareFill>
                <BsCaretRightSquareFill className="absolute top=[-50%] text-white text-3xl cursor-pointer right-5" onClick={nextSlide}></BsCaretRightSquareFill>
                {
                    imgs && imgs.map((images,i)=>(
                        <div className= {i === current ? 'opacity-100 w-full' : 'opacity-0'} key={i}>
                            {i === current && (
                                <img className="w-full h-[300px] md:h-[400px] object-cover" src={images} alt="" />
                            )}
                        </div>
                    ))
                }
            </div>
        </>
    )
}