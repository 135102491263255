import React from 'react';


export function BusinessPolicy(){

    return(
        <>
            <div className="max-w-full mx-auto bg-red-900">
                <div className=" text-xl lg:text-3xl text-white justify-center items-center text-center ">
                    <p className="pt-10">Business No Show/Cancellation Policy</p>
                </div>
                <div className="max-w-screen-md mx-auto justify-center items-center pt-10 px-10 lg:px-0">
                    <p className="text-white">
                        As you all know I am currently working by myself, I am booked 
                        in advance (Depending on service)meaning all my appointments are back to back! That being said, while
                        I do my best to try and accommodate everyone, my time is very valuable so to respect my time, and yours 
                        I will NO LONGER be accepting appointments by call or text from anyone! From here on  I request to book all
                        the appointments through my website at ritazparlor@gmail.com.
                    </p>
                    <p className="text-white py-8">
                        With this new policy, a debit card will be required to book with me your card will NOT be charged for
                        booking! The reason for this new policy is due to an increased amount of no-shows and cancellations. Most 
                        of you know my NO SHOW/ CANCELLATION POLICY but just a friendly reminder if you need to cancel your appointment I
                        need to be notified 24 hours in advance so that I am able to fill your spot, if you do not let me know 24 hours in 
                        advance you will be CHARGED 50% of your service fees this policy will also be in effect if you are more than 10 minutes
                        late. If you are going to be more than 10 minutes late you will need to reschedule which means you will also be charged 
                        50% of your service fees! Please understand none of this is to be mean or rude but as I mentioned earlier I need everyone
                        to respect my time just as much as I respect all of yours! Thank you all for understanding.
                    </p>
                </div>
            </div>
        </>
    )
}