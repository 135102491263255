import React from 'react';

import Img from '../../../image/bgImage.jpg'
import Mem1 from '../../../image/new1.jpg'
import Mem2 from '../../../image/new2.jpg'
import Mem3 from '../../../image/new3.jpg'
import Mem4 from '../../../image/new4.jpg'
import { NavLink } from 'react-router-dom';

export function Members(){

    return(
        <>
              <div className="max-w-full mx-auto pb-5">
               <div className="relative">
                <img className="h-[150px] lg:h-[200px] w-full object-cover" src={Img} alt="Img"></img>
                <div className="absolute justify-center items-center h-full left-0 top-[60px] lg:top-[100px]">
                    <div className="justify-center text-center text-0.5xl lg:text-xl py-10 lg:py-1 px-[130px] lg:px-[550px]">
                       <p className="text-yellow-400 font-bold text-2xl md:text-3xl uppercase"> Meet Our Team</p> 
                    </div>
                </div>
               </div>
            </div>

            <div className="max-w-[1240px] mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-y-5 lg:gap-y-0 gap-x-5 pb-5 lg:py-10 px-5 lg:px-0">
                <div className="bg-gray-200 flex flex-col ">
                    <div className="p-3">
                        <img className="h-[400px] w-full object-cover" src={Mem1} alt="Img" />
                    </div>
                    <div className="flex flex-col p-3 justify-center text-center">
                        <p className="text-xl font-semibold uppercase">Member 1</p>
                        <p className="text-xl pt-3">Services: Microblending and Lash Lift</p>
                        <p className="font-bold pb-5">Phone: (614) 564 7678</p>
                        <NavLink to="/member-1"><button className="py-2 w-full justify-center text-center uppercase bg-orange-800  px-10 rounded-lg font-semibold
                            text-white hover:bg-gray-600 hover:text-black transition duration-300">View Services</button>
                        </NavLink>
                        <NavLink className="py-3" to="/online-booking"><button className="py-2 w-full justify-center text-center uppercase bg-gray-600  px-10 rounded-lg font-semibold
                            text-white hover:bg-orange-800 hover:text-black transition duration-300">Book Online</button>
                        </NavLink>
                    </div>
                </div>
                <div className="bg-gray-200 flex flex-col ">
                    <div className="p-3">
                        <img className="h-[400px] w-full object-cover" src={Mem2} alt="Img" />
                    </div>
                    <div className="flex flex-col p-3 justify-center text-center">
                        <p className="text-xl font-semibold uppercase">Member 2</p>
                        
                        <p className="text-xl pt-3">Services: Hairstylist and Lash Lift</p>
                        <p className="font-bold pb-5">Phone: (614) 564 5645</p>
                        <NavLink to=""><button className="py-2 w-full justify-center text-center uppercase bg-orange-800  px-10 rounded-lg font-semibold
                            text-white hover:bg-gray-600 hover:text-black transition duration-300">View Services</button>
                        </NavLink>
                        <NavLink className="py-3" to="/online-booking"><button className="py-2 w-full justify-center text-center uppercase bg-gray-600  px-10 rounded-lg font-semibold
                            text-white hover:bg-orange-800 hover:text-black transition duration-300">Book Online</button>
                        </NavLink>
                    </div>
                </div>
                <div className="bg-gray-200 flex flex-col ">
                    <div className="p-3">
                        <img className="h-[400px] w-full object-cover" src={Mem3} alt="Img" />
                    </div>
                    <div className="flex flex-col p-3 justify-center text-center">
                        <p className="text-xl font-semibold uppercase">Member 3</p>
                        
                        <p className="text-xl pt-3">Services: Hairstylist</p>
                        <p className="font-bold pb-5">Phone: (614) 564 7854</p>
                        <NavLink to=""><button className="py-2 w-full justify-center text-center uppercase bg-orange-800  px-10 rounded-lg font-semibold
                            text-white hover:bg-gray-600 hover:text-black transition duration-300">View Services</button>
                        </NavLink>
                        <NavLink className="py-3" to="/online-booking"><button className="py-2 w-full justify-center text-center uppercase bg-gray-600  px-10 rounded-lg font-semibold
                            text-white hover:bg-orange-800 hover:text-black transition duration-300">Book Online</button>
                        </NavLink>
                    </div>
                </div>
                <div className="bg-gray-200 flex flex-col ">
                    <div className="p-3">
                        <img className="h-[400px] w-full object-cover" src={Mem4} alt="Img" />
                    </div>
                    <div className="flex flex-col p-3 justify-center text-center">
                        <p className="text-xl font-semibold uppercase">Member 4</p>
                        
                        <p className="text-xl pt-3">Services: </p>
                        <p className="font-bold pb-5">Phone: (614) 564 7678</p>
                        <NavLink to=""><button className="py-2 w-full justify-center text-center uppercase bg-orange-800  px-10 rounded-lg font-semibold
                            text-white hover:bg-gray-600 hover:text-black transition duration-300">View Services</button>
                        </NavLink>
                        <NavLink className="py-3" to="/online-booking"><button className="py-2 w-full justify-center text-center uppercase bg-gray-600  px-10 rounded-lg font-semibold
                            text-white hover:bg-orange-800 hover:text-black transition duration-300">Book Online</button>
                        </NavLink>
                    </div>
                </div>
            </div>
        </>
    )
}