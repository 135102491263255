import React from 'react';

import Img from '../../image/bgImage.jpg'
import { NavLink } from 'react-router-dom';

export function ErrorPage(){

    return(
        <>
            <div className="max-w-full mx-auto h-screen">
               <div className="relative">
                <img className="h-[100px] w-full object-cover" src={Img} alt="Img"></img>
                <div className="absolute justify-center items-center h-full left-0 top-0">
                    <div className="justify-center items-center text-center text-0.5xl lg:text-xl text-black pt-10">
                       <p className="text-black lg:text-yellow-300 justify-center items-center font-bold text-3xl md:text-3xl uppercase px-10 lg:px-[450px]"> 404 - Page not found.</p> 
                    </div>
                </div>
               </div>

               <div className="max-w-screen-md mx-auto pt-10">
                    <div className="flex flex-col py-4 items-center justify-center text-center">
                        <p className="uppercase text-2xl font-semibold">Page You Are Looking Is not Found</p>
                        <p>The page you are looking for does not exist.</p>
                        <div className="flex py-5 justify-center items-center">
                           <NavLink to="/">
                           <button className="bg-black py-2  px-10 rounded-lg font-semibold
                            text-white hover:bg-orange-500 hover:text-black transition duration-300">Back To Home</button>
                           </NavLink>
                        </div>
                    </div>
               </div>
            </div>
        </>
    )
}