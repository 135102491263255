import React from 'react';
import { NavLink } from 'react-router-dom';

export function ContactUs(){

    return(
        <>
             <div className="max-w-full mx-auto bg-white">
               <div className="text-3xl text-orange-700 justify-center items-center text-center flex flex-col ">
                <p className="pt-10 font-mono">Contact Us</p>
                <p className="py-5 text-xl">Drop us a line!</p>
               </div>
               <div className="max-w-screen-md mx-auto justify-center items-center px-5 lg:px-0">
                    <form >
                        <div className="flex flex-col w-full">
                            <label className="py-2 font-semibold">Email *:</label>
                            <input type="email"  defaultValue='' placeholder="Enter Email" className="h-[40px] rounded-lg px-3 drop-shadow-lg w-full border" />
                            <span className="text-danger"></span>
                        </div>
                        <div className="flex flex-col w-full">
                            <label className="py-2 font-semibold">Name:</label>
                            <input type="text" defaultValue='' placeholder="Enter Name" className="h-[40px] rounded-lg px-3 drop-shadow-lg w-full border" />
                            <span className="text-danger"></span>
                        </div>
                        <div className="flex flex-col w-full">
                            <label className="py-2 font-semibold">Message:</label>
                            <textarea type="text" defaultValue='' placeholder="Type Message" className="h-[100px] rounded-lg px-3 drop-shadow-lg w-full border" />
                            <span className="text-danger"></span>
                        </div>
                       
                        <div className="flex py-5 justify-center items-center">
                            <button type="submit" className="bg-red-800 py-2  px-10 rounded-lg font-semibold
                            text-white hover:bg-red-500 hover:text-black transition duration-300">Send</button>
                        </div>
                    </form>
                    <p className="justify-center items-center text-center font-[0.3px]">This site is protected by reCAPTCHA and the Google <NavLink to="" className="hover:text-red-300">Privacy Policy</NavLink> and <NavLink to="" className="hover:text-red-300">Terms of Service </NavLink> apply.</p>
               </div>
            </div>
        </>
    )
}