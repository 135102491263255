import React from 'react';

export function Details(){

    return(
        <>
            <div className="max-w-full mx-auto bg-white">
               <div className="max-w-screen-md mx-auto flex justify-center text-center  text-xl gap-x-10 py-10 px-5 lg:px-0">
                    <div className="flex flex-col">
                       <div>
                            <div>Ritaz Parlor</div>
                            <div>Indian Mound Mall</div>
                            <div>771 South 30th Street</div>
                            <div>Heath OH 43056</div>
                       </div>
                       <div className="pt-4">
                            <div>315-760-2078</div>
                            <div>ritazparlor@gmail.com</div>
                       </div>
                    </div>

                    <div className="flex flex-col">
                        <div>
                            <div>Hours</div>
                            <div>Sun -12pm -6pm</div>
                            <div>Mon, Wed, Thru, Fri, Sat -11am -7pm</div>
                            
                        </div>
                        <div className="pt-4">
                            <div>Tue - Closed</div>
                                                       
                        </div>
                    </div>
               </div>
            </div>
        </>
    )
}