
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Home } from './components/home/home';
import { Layout } from './components/layout/layout';
import { Members } from './components/front/meet-our-team/members';
import { Gallery } from './components/front/gallery/gallery';
import { MemberDetail } from './components/front/meet-our-team/member-detail';
import { SingleAboutPage } from './components/front/about/single-about-page';
import { OwnAStudio } from './components/front/own-a-studio/own-a-studio';
import { Services } from './components/front/services/services';
import { ServiceDetail } from './components/front/services/service-detail';
import { Feedback } from './components/front/review/review';
import { CustomerReview } from './components/front/review/customer-reviews';
import { ErrorPage } from './components/error-page/error-page';
import { BookingForm } from './components/front/meet-our-team/member-booking';
import { AdminHome } from './components/admin/home/adminhome';
import { AdminLayout } from './components/admin/layout/adminlayout';
import { AdminAbout } from './components/admin/admin-about/admin-about';
import { AddAboutUs } from './components/admin/admin-about/add-about';
import { UpdateAboutUs } from './components/admin/admin-about/update-about';
import { ViewAboutUs } from './components/admin/admin-about/view-about';
import { Login } from './components/front/login/login';
import { Register } from './components/front/register/register';

function App() {
  
  return (
    <BrowserRouter>
      <Routes>
          <Route path="/" element={<Layout />}>
              <Route index element={<Home />}></Route>
              <Route path="/meet-our-team" element={<Members />}></Route>
              <Route path="/member-1" element={<MemberDetail />}></Route>
              <Route path="/services" element={<Services />}></Route>
              <Route path="/services/hair" element={<ServiceDetail />}></Route>
              <Route path="/gallery" element={<Gallery />}></Route>
              <Route path="/own-a-studio" element={<OwnAStudio />}></Route>
              <Route path="/about-us" element={<SingleAboutPage />}></Route>
              <Route path="/review" element={<Feedback />}></Route>
              <Route path="/customer/reviews" element={<CustomerReview />}></Route>
              <Route path="/online-booking" element={<BookingForm />}></Route>
              <Route path="/login" element={<Login />}></Route>
              <Route path="/register" element={<Register />}></Route>
              <Route path="*" element={<ErrorPage />}></Route>
          </Route>

          <Route path="/admin" element={<AdminLayout />}>
              <Route index element={<AdminHome />}></Route>

              <Route path="/admin/about-us" element={<AdminAbout />}></Route>
              <Route path="/admin/add-about-us" element={<AddAboutUs />}></Route>
              <Route path="/admin/update-about-us" element={<UpdateAboutUs />}></Route>
              <Route path="/admin/view-about-us" element={<ViewAboutUs />}></Route>
          </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
