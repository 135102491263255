import React from 'react'
import { Outlet } from 'react-router-dom'
import { AdminHeader } from '../header/admin-header'
import { Sidebar } from '../sidebar/sidebar'

export function AdminLayout(){

    return(
        <div className="flex flex-row h-screen w-screen overflow-hidden">
            <Sidebar />
           <div className="flex-1">
                <AdminHeader />
                <Outlet />
           </div>
        </div>
    )
}