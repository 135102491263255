import React from 'react'

import {FaRegEdit} from 'react-icons/fa'
import {AiOutlineDelete} from 'react-icons/ai'
import axios from 'axios'
import { NavLink } from 'react-router-dom'

export function AdminAbout(){

    return(
            <div className="flex flex-col p-10">
            <div className="flex justify-between items-center">
                <p className="text-2xl text-semibold">Dashboard</p>
                <p>Home/About Us</p>
            </div>
            <div className="pt-10">
                <div className="bg-gray-200 px-5 drop-shadow-lg rounded-lg">
                    <div className="py-2">
                        <div className="flex justify-between items-center py-3">
                            <p className="text-2xl font-medium">About Us</p>
                        <NavLink to="/admin/add-about-us">
                            <button className="bg-gray-500 w-[100px] rounded-md h-8 hover:bg-gray-400 text-center ">ADD</button>
                        </NavLink>
                        </div>
                        <div className="flex justify-between items-center py-2">
                            <div className="pb-2"></div>
                            <div className="pb-2 dark:bg-gray-900">
                                <label htmlFor="table-search" className="sr-only">Search</label>
                                <div className="relative mt-1">
                                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                        <svg className="w-5 h-5 text-gray-500 dark:text-gray-400" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd"></path></svg>
                                    </div>
                                    <input type="text" id="table-search" className="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search ......" />
                                </div>
                            </div>
                        </div>

                        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                    <tr>
                                        <th scope="col" className="p-4">
                                            <div className="flex items-center">
                                                <input id="checkbox-all-search" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                <label htmlFor="checkbox-all-search" className="sr-only">checkbox</label>
                                            </div>
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Name
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                         BG Image
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Description
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* {
                                        admissionRequest &&
                                        admissionRequest.map((admiss,index)=>(
                                            <tr key={index + 1} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                                <td className="w-4 p-4">
                                                    <div className="flex items-center">
                                                        <input id="checkbox-table-search-1" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                        <label htmlFor="checkbox-table-search-1" className="sr-only">checkbox</label>
                                                    </div>
                                                </td>
                                                <th scope="row" className="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap dark:text-white">
                                                
                                                    <div className="pl-3">
                                                        <div className="text-base font-semibold">{admiss.user_id.name}</div>
                                                        <div className="font-normal text-gray-500"></div>
                                                    </div>  
                                                </th>
                                                <td className="px-6 py-4">
                                                    {admiss.forClass.name}
                                                </td>
                                                <td className="px-6 py-4">
                                                    <div className="flex items-center">
                                                        <div className={ admiss.is_approved === 'Approved' ? "h-2.5 w-2.5 rounded-full bg-green-500 mr-2" : "h-2.5 w-2.5 rounded-full bg-red-500 mr-2"}></div> {admiss.is_approved}
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 flex gap-x-2">
                                                <NavLink to={"/admin/admission/"+ admiss._id}>
                                                        <FaRegEdit size={25}  />
                                                </NavLink>
                                                    <AiOutlineDelete size={25}  />
                                                </td>
                                            </tr>
                                        ))
                                    } */}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}