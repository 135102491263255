import axios from 'axios'
import React, {useEffect, useState } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'


export function UpdateAboutUs(){

    const commonField = {
        user_id: '',
        forClass: '',
        rollNo: '',
        is_approved: '',
    }

    const [inputValue,setInputValue] = useState(commonField)

    const navigate = useNavigate();
    const params = useParams();

    // useEffect(()=>{
    //     axios.get('http://localhost:9000/admission/'+ params.id, inputValue)
    //     .then((response)=>{
    //         // console.log(response.data.data)
    //         setInputValue(response.data.data)
    //     })
    //     .catch((error)=>{
    //         //
    //     })
    // },[]);

    const handleChange = (e) =>{
        const {name,value} = e.target;
        setInputValue({...inputValue, [name]:value})
    }

    // const handleSubmit = (ev) =>{
    //     ev.preventDefault();
    //     axios.put('http://localhost:9000/admission/' + params.id, inputValue)
    //     .then((response) => {
    //         // console.log(response.data.data)
    //       if(response.data.status === 200){
    //         setInputValue(response.data.data)
    //         navigate('/admin/admission')
    //       }
    //     })
    //     .catch((error)=>{
    //         //
    //     })
    // }

    return(
        <>
            <div className="flex flex-col p-10">
                <div className="flex justify-between items-center">
                    <p className="text-2xl text-semibold">Dashboard</p>
                    <p>Home/Update About Us</p>
                </div>
                <div className="pt-10">
                    <div className="bg-gray-200 px-5 drop-shadow-lg rounded-lg">
                        <div className="py-2">                        
                            <div className="relative overflow-x-auto shadow-md sm:rounded-lg w-full">
                                <div className="relative w-full h-full md:h-auto">
                                        
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

