import React, { useEffect, useState } from 'react';
import {GiHamburgerMenu} from 'react-icons/gi'
import {GrClose} from 'react-icons/gr'
import {BsFacebook} from 'react-icons/bs'
import {AiFillInstagram} from 'react-icons/ai'
import {FaLocationDot} from 'react-icons/fa6'
import { NavLink } from 'react-router-dom'

import Img from '../../image/eye.webp'

export function Header(){

    return(
        <div>Header</div>
    )
}

export function MenuSection(){

    const [nav, setNav] = useState(false)

    const handleClick = (ev) => {
        setNav(!nav)
    }

     const [position, setPosition] = useState(window.pageYOffset)
    const [visible, setVisible] = useState(true) 
    useEffect(()=> {
        const handleScroll = () => {
           let moving = window.pageYOffset
           
           setVisible(position > moving);
           setPosition(moving)
        };
        window.addEventListener("scroll", handleScroll);
        return(() => {
           window.removeEventListener("scroll", handleScroll);
        })
    })

  const cls = visible ? "hidden" : "visible";
  const ssd = visible ? "visible" : "hidden";


    return(
        <>
            {/* <div className={`w-screen mx-auto bg-yellow-400 top-0 h-10 sticky z-10  ${ssd}`}>
                <div className="max-w-[1240px] mx-auto py-2 flex justify-between items-center">
                    <div className="flex items-center space-x-2">
                        <div> <FaLocationDot className="text-white" /></div>
                        <div> <p className="text-white uppercase text-0.5">Parlor, Albany, USA</p></div>
                    </div>
                    <div className="flex items-center space-x-2">
                        <div><NavLink to=""><p className="text-white"><BsFacebook /></p></NavLink></div>
                       <div> <NavLink to=""><p className="text-white"><AiFillInstagram /></p></NavLink></div>
                    </div>
                </div>
            </div> */}

            {/* <div className={`w-screen mx-auto bg-gray-100 h-[80px] top-0 fixed z-10 ${cls ? "sticky" : ""}`}>
                <div className="max-w-[1240px] mx-auto flex justify-between items-center">
                    <div className="px-3 lg:px-0">
                        <NavLink to="/">
                            <img className="h-[60px] w-[100px] object-cover cursor-pointer" src={Img} alt="Img" />
                        </NavLink>
                    </div>
                    <ul className="hidden lg:flex text-black right-0">
                        <li className="font-semibold px-5"><NavLink to="/" className="text-black active:text-blue-500">HOME</NavLink></li>
                        <li className="font-semibold px-5"><NavLink to="/meet-our-team">MEET OUR TEAM</NavLink></li>
                        <li className="font-semibold px-5"><NavLink to="/services">SERVICES</NavLink></li>                                        
                        <li className="font-semibold px-5"><NavLink to="/gallery">GALLERY</NavLink></li>                                        
                        <li className="font-semibold px-5"><NavLink to="/own-a-studio">OWN A STUDIO</NavLink></li>                                        
                        <li className="font-semibold px-5"><NavLink to="/about-us">ABOUT</NavLink></li>                                        
                    </ul>
                    <div className="lg:hidden pr-7" onClick={handleClick}>
                        { nav ? <GrClose size={25} /> : <GiHamburgerMenu size={25} />}
                    </div>
                </div>
                <ul className={ !nav ? "hidden" : "absolute text-black bg-gray-100 w-full px-5"}>
                    <li className="font-semibold py-2 border-b-2"><NavLink to="/">HOME</NavLink></li>
                    <li className="font-semibold py-2 border-b-2"> <NavLink to="/meet-our-team">MEET OUR TEAM</NavLink></li>
                    <li className="font-semibold py-2 border-b-2"> <NavLink to="/services">SERVICES</NavLink> </li>
                    <li className="font-semibold py-2 border-b-2"> <NavLink to="/gallery">GALLERY</NavLink> </li>
                    <li className="font-semibold py-2 border-b-2"> <NavLink to="/own-a-studio">OWN A STUDIO</NavLink> </li>
                    <li className="font-semibold py-2 border-b-2"> <NavLink to="/about-us">ABOUT</NavLink> </li>
                </ul>
            </div> */}

            <div className={`w-screen mx-auto bg-gray-100 h-[80px] top-0 fixed z-10`}>
                <div className="max-w-[1240px] mx-auto flex justify-between items-center">
                    <div className="px-3 lg:px-0">
                        <NavLink to="/">
                            <img className="h-[60px] w-[100px] object-cover cursor-pointer pt-2" src={Img} alt="Img" />
                        </NavLink>
                    </div>
                    <ul className="hidden lg:flex text-black right-0">
                        <li className="font-semibold px-5"><NavLink to="/" className="text-black active:text-blue-500">HOME</NavLink></li>
                        <li className="font-semibold px-5"><NavLink to="/meet-our-team">MEET OUR TEAM</NavLink></li>
                        <li className="font-semibold px-5"><NavLink to="/services">SERVICES</NavLink></li>                                        
                        <li className="font-semibold px-5"><NavLink to="/gallery">GALLERY</NavLink></li>                                        
                        <li className="font-semibold px-5"><NavLink to="/own-a-studio">OWN A STUDIO</NavLink></li>                                        
                        <li className="font-semibold px-5"><NavLink to="/about-us">ABOUT</NavLink></li>                                        
                    </ul>
                    <div className="lg:hidden pr-7" onClick={handleClick}>
                        { nav ? <GrClose size={25} /> : <GiHamburgerMenu size={25} />}
                    </div>
                </div>
                <ul className={ !nav ? "hidden" : "absolute text-black bg-gray-100 w-full px-5"}>
                    <li className="font-semibold py-2 border-b-2"><NavLink to="/">HOME</NavLink></li>
                    <li className="font-semibold py-2 border-b-2"> <NavLink to="/meet-our-team">MEET OUR TEAM</NavLink></li>
                    <li className="font-semibold py-2 border-b-2"> <NavLink to="/services">SERVICES</NavLink> </li>
                    <li className="font-semibold py-2 border-b-2"> <NavLink to="/gallery">GALLERY</NavLink> </li>
                    <li className="font-semibold py-2 border-b-2"> <NavLink to="/own-a-studio">OWN A STUDIO</NavLink> </li>
                    <li className="font-semibold py-2 border-b-2"> <NavLink to="/about-us">ABOUT</NavLink> </li>
                </ul>
            </div>
        </>
        
    )
}