import React from 'react';

import Img from '../../../image/bgImage.jpg'

export function BookingForm(){

    return(
        <>
            <div className="w-screen mx-auto pb-5">
               <div className="relative">
                <img className="h-[150px] lg:h-[200px] w-full object-cover" src={Img} alt="Img"></img>
                <div className="absolute justify-center items-center h-full left-0 top-[60px] lg:top-[100px]">
                    <div className="justify-center text-center text-0.5xl lg:text-xl py-10 lg:py-1 px-[120px] md:px-[250px] lg:px-[550px]">
                       <p className="text-yellow-400 font-bold text-2xl md:text-3xl uppercase"> Online Booking</p> 
                    </div>
                </div>
               </div>
            </div>

            <div className="max-w-screen-md mx-auto justify-center items-center px-5 lg:px-0">
                <form >
                    <div className="flex flex-col md:flex-row w-full md:space-x-12">
                        <div className="flex flex-col ">
                            <label className="py-2 font-semibold">Service Provided By:</label>
                            <input type="name"  defaultValue='' placeholder="Enter Name" className="h-[40px] rounded-lg px-3 drop-shadow-lg w-[350px] border" />
                            <span className="text-danger"></span>
                        </div>
                        <div className="flex flex-col">
                            <label className="py-2 font-semibold">Service Name:</label>
                            <input type="text"  defaultValue='' placeholder="Enter Service" className="h-[40px] rounded-lg px-3 drop-shadow-lg w-[350px] border" />
                            <span className="text-danger"></span>
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row w-full md:space-x-12">
                        <div className="flex flex-col">
                            <label className="py-2 font-semibold">Provider Phone Number:</label>
                            <input type="number"  defaultValue='' placeholder="Enter Contact Number" className="h-[40px] rounded-lg px-3 drop-shadow-lg w-[350px] border" />
                            <span className="text-danger"></span>
                        </div>
                        <div className="flex flex-col">
                            <label className="py-2 font-semibold">Customer Name:</label>
                            <input type="name"  defaultValue='' placeholder="Enter Name" className="h-[40px] rounded-lg px-3 drop-shadow-lg w-[350px] border" />
                            <span className="text-danger"></span>
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row w-full md:space-x-12">
                        <div className="flex flex-col ">
                            <label className="py-2 font-semibold">Customer Phone No:</label>
                            <input type="number"  defaultValue='' placeholder="Enter Contact No" className="h-[40px] rounded-lg px-3 drop-shadow-lg w-[350px] border" />
                            <span className="text-danger"></span>
                        </div>
                        <div className="flex flex-col">
                            <label className="py-2 font-semibold">Customer Email:</label>
                            <input type="email"  defaultValue='' placeholder="Enter Email" className="h-[40px] rounded-lg px-3 drop-shadow-lg w-[350px] border" />
                            <span className="text-danger"></span>
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row w-full md:space-x-12">
                        <div className="flex flex-col">
                            <div  className="py-2"><label className="py-2 font-semibold">Service Date:</label></div>
                            <div><input type="date"  defaultValue='' placeholder="Enter Date" className="h-[40px] rounded-lg px-3 drop-shadow-lg w-[350px] border" />
                            <span className="text-danger"></span></div>
                        </div>
                        <div className="flex flex-col">
                            <div className="py-2"><label className="py-2 font-semibold">Service Time:</label></div>
                            <div><input type="time"  defaultValue='' placeholder="Enter Time" className="h-[40px] rounded-lg px-3 drop-shadow-lg w-[350px] border" />
                            <span className="text-danger"></span></div>
                        </div>
                    </div>
                    
                    <div className="flex py-5 items-start">
                        <button type="submit" className="bg-gray-600 py-2  px-10 rounded-lg font-semibold
                        text-white hover:bg-yellow-500 hover:text-black transition duration-300">Send</button>
                    </div>
                </form>
            </div>

            
        </>
    )
}