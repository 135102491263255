import React from 'react';

import Img from '../../../image/eye.webp';

export function Banner(){

    return(
        <>
            <div className="w-full mx-auto bg-black grid grid-cols-1 lg:grid-cols-2 justify-between lg:px-0">
                
                <img src={Img} alt="" className="w-full h-[600px] lg:h-screen  object-cover" />

                <div className="flex flex-col justify-center text-white text-center px-[30px] lg:px-40 pb-5 lg:pb-0">
                    <p className="text-2xl font-bold py-3">Once Eyebrow Threading, Always Eyebrow Threading, eyebrow threading serivce.</p>
                    <p className="text-xl font-semibold pb-3 text-gray-500">Eyebrow Threading is an ancient hair-removal technique, originated in India. A cotton thread is used to shape the eyebrows.</p>
                    <p className="text-xl font-semibold pt-4 text-gray-500"> 315-760-2078  </p>
                </div>
            </div>
        </>
    )
}