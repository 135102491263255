import React from 'react';


export function ServiceOffer(){

    return(
        <>
             <div className="max-w-full mx-auto bg-gray-200">
               <div className="text-3xl text-orange-700 justify-center items-center text-center ">
                <p className="pt-10">Services We Offer</p>
               </div>
               <div className="max-w-screen-md mx-auto pt-10 pb-10 px-10 lg:px-0">
                    <div className="flex flex-col">
                        <div className="text-orange-700 text-3xl flex"><p>THREADING</p></div>
                        <div className="flex justify-between py-2 text-xl">
                            <p>Eyebrow Threading</p>
                            <p>$15.00</p>
                        </div>
                        <div className="flex justify-between py-2 text-xl">
                            <p>Upper Lip Threading</p>
                            <p>$8.00</p>
                        </div>
                        <div className="flex justify-between py-2 text-xl">
                            <p>Chin Threading</p>
                            <p>$8.00</p>
                        </div>
                        <div className="flex justify-between py-2 text-xl">
                            <p>Forehead Threading</p>
                            <p>$8.00</p>
                        </div>
                        <div className="flex justify-between py-2 text-xl">
                            <p>Neck Threading</p>
                            <p>$8.00</p>
                        </div>
                        <div className="flex justify-between py-2 text-xl">
                            <p>Sides Threading</p>
                            <p>$16.00</p>
                        </div>
                        <div className="flex justify-between py-2 text-xl">
                            <p>Full Face Threading</p>
                            <p>$40.00</p>
                        </div>
                    </div>
                    {/* <hr class="max-w-[1240px] mx-auto h-1 my-4 bg-gray-100 border-0 rounded md:my-10 dark:bg-gray-700" /> */}
                    <hr class="h-px my-8 bg-orange-300 border-0 dark:bg-orange-700"></hr>

                    <div className="flex flex-col">
                        <div className="text-orange-700 text-3xl"><p>Tinting</p></div>
                        <div className="flex justify-between py-2 text-xl">
                            <div>Eyebrow Tint</div>
                            <div>$20.00</div>
                        </div>
                        <div className="flex justify-between py-2 text-xl">
                            <p>Eyelash Tint</p>
                            <p>$20.00</p>
                        </div>
                        <div className="flex justify-between py-2 text-xl">
                            <p>Henna Tint</p>
                            <p>$30.00</p>
                        </div>
                    </div>

                    <hr class="h-px my-8 bg-orange-300 border-0 dark:bg-orange-700"></hr>

                    <div className="flex flex-col">
                        <div className="text-orange-700 text-3xl"><p>Waxing</p></div>
                        <div className="flex justify-between py-2 text-xl">
                            <div>Eyebrow </div>
                            <div>$12.00</div>
                        </div>
                        <div className="flex justify-between py-2 text-xl">
                            <p>Upper Lip</p>
                            <p>$6.00</p>
                        </div>
                        <div className="flex justify-between py-2 text-xl">
                            <p>Chin</p>
                            <p>$6.00</p>
                        </div>
                        <div className="flex justify-between py-2 text-xl">
                            <p>Forehead</p>
                            <p>$6.00</p>
                        </div>
                        <div className="flex justify-between py-2 text-xl">
                            <p>Neck</p>
                            <p>$6.00</p>
                        </div>
                        <div className="flex justify-between py-2 text-xl">
                            <p>Sides</p>
                            <p>$14.00</p>
                        </div>
                        <div className="flex justify-between py-2 text-xl">
                            <p>Full Face</p>
                            <p>$30.00</p>
                        </div>
                        <div className="flex justify-between py-2 text-xl">
                            <p>Under Arms</p>
                            <p>$15.00</p>
                        </div>
                        <div className="flex justify-between py-2 text-xl">
                            <p>Half Arms</p>
                            <p>$20.00</p>
                        </div>
                        <div className="flex justify-between py-2 text-xl">
                            <p>Full Arms</p>
                            <p>$40.00</p>
                        </div>
                        <div className="flex justify-between py-2 text-xl">
                            <p>Half Legs</p>
                            <p>$30.00</p>
                        </div>
                        <div className="flex justify-between py-2 text-xl">
                            <p>Full Legs</p>
                            <p>$60.00</p>
                        </div>
                    </div>

                    <hr class="h-px my-8 bg-orange-300 border-0 dark:bg-orange-700"></hr>

                    <div className="flex flex-col">
                        <div className="text-orange-700 text-3xl"><p>Eyelash</p></div>
                        <div className="flex justify-between py-2 text-xl">
                            <div>Cluster Fan Eyelash</div>
                            <div>$30.00</div>
                        </div>
                        <div className="flex justify-between py-2 text-xl">
                            <p>Stipe</p>
                            <p>$15.00</p>
                        </div>
                    </div>
                    <hr class="h-px my-8 bg-orange-300 border-0 dark:bg-orange-700"></hr>

                    <div className="flex flex-col">
                        <div className="text-orange-700 text-3xl"><p>Henna Tattoos</p></div>
                        <div className="flex justify-between py-2 text-xl">
                            <div>Tattoos</div>
                            <div>$5 and Up</div>
                        </div>
                    
                    </div>
               </div>
            </div>
        </>
    )
}