import React from 'react';
import { Outlet } from 'react-router-dom';
import { MenuSection } from '../header/header';
import { Footer } from '../footer/footer';

export function Layout(){

    return(
        <>
            {/* <Header /> */}
            <MenuSection />
                <Outlet />
            <Footer />
        </>
    )
}