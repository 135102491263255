import React, { useState } from 'react'
import {FaStar} from 'react-icons/fa'

export function Feedback(){

    const [rating, setRating] = useState(null)
    const [hover, setHover] = useState(null)

    return(
        <>
             <div className="max-w-full mx-auto bg-white pt-20">
               <div className="text-3xl text-orange-700 justify-center items-center text-center ">
                <p className="pt-10 font-mono">Customer Review</p>
               </div>
               <div className="max-w-screen-md mx-auto justify-center items-center px-5 lg:px-0">
                    <form >
                        <div className="flex flex-col w-full">
                            <label className="py-2 font-semibold">Name :</label>
                            <input type="text" defaultValue='' placeholder="Enter Name" className="h-[40px] rounded-lg px-3 drop-shadow-lg w-full border" />
                            <span className="text-danger"></span>
                        </div>

                        <div className="flex flex-col w-full">
                            <label className="py-2 font-semibold">Email :</label>
                            <input type="email"  defaultValue='' placeholder="Enter Email" className="h-[40px] rounded-lg px-3 drop-shadow-lg w-full border" />
                            <span className="text-danger"></span>
                        </div>

                        <div className="flex flex-col w-full">
                            <label className="py-2 font-semibold">Rating* :</label>
                            <div className="flex ">
                                {[...Array(5)].map((star,i)=>{
                                    const ratingValue = i + 1;
                                    return(
                                        <label className="flex">
                                            <input className="hidden" type="radio" name="rating" value={ratingValue} onClick={()=>setRating(ratingValue)} />
                                            <FaStar className="star" size={60} color={ratingValue <= (hover || rating) ? "#ffc107" : "#e4e5e9"}  onMouseEnter={()=>setHover(ratingValue)} onMouseLeave={()=>setRating(null)}/>
                                        </label>
                                    )
                                })}
                            </div>
                             {/* <p>The rating is {rating}</p> */}
                            <span className="text-danger"></span>
                        </div>
                        
                        <div className="flex flex-col w-full">
                            <label className="py-2 font-semibold">Feedback* :</label>
                            <textarea type="text" defaultValue='' placeholder="Whats your feedback?" className="h-[100px] rounded-lg px-3 drop-shadow-lg w-full border" />
                            <span className="text-danger"></span>
                        </div>
                       
                        <div className="flex py-5 justify-center items-center">
                            <button type="submit" className="bg-red-800 py-2  px-10 rounded-lg font-semibold
                            text-white hover:bg-red-500 hover:text-black transition duration-300">Submit</button>
                        </div>
                    </form>
               </div>
            </div>
        </>
    )
}