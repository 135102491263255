import axios from 'axios'
import React, {useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'


export function AddAboutUs(){

    const commonField = {
        user_id: '',
        forClass: '',
        rollNo: '',
        is_approved: 'Approved',
    }

    const [inputValue,setInputValue] = useState(commonField)
    const [users,setUsers] = useState([])
    const [classes,setClasses] = useState([])

    const navigate = useNavigate();

    // useEffect(()=>{
    //     axios.get('http://localhost:9000/user')
    //     .then((response)=>{
    //         setUsers(response.data.data)
    //     })
    //     .catch((error)=>{
    //         //
    //     })
    // },[]);

    // useEffect(()=>{
    //     axios.get('http://localhost:9000/classes')
    //     .then((response)=>{
    //         setClasses(response.data.data)
    //     })
    //     .catch((error)=>{
    //         //
    //     })
    // },[]);

    // const handleChange = (e) =>{
    //     const {name,value} = e.target;
    //     setInputValue({...inputValue, [name]:value})
    // }

    // const handleSubmit = (ev) =>{
    //     ev.preventDefault();
    //     axios.post('http://localhost:9000/admission', inputValue)
    //     .then((response) => {
    //         // console.log(response.data.data)
    //       if(response.data.status === 200){
    //         setInputValue(response.data.data)
    //         navigate('/admin/admission')
    //       }
    //     })
    //     .catch((error)=>{
    //         //
    //     })
    // }

    return(
        <>
            <div className="flex flex-col p-10">
                <div className="flex justify-between items-center">
                    <p className="text-2xl text-semibold">Dashboard</p>
                    <p>Home/About Us</p>
                </div>
                <div className="pt-10">
                    <div className="bg-gray-200 px-5 drop-shadow-lg rounded-lg">
                        <div className="py-2">                        
                            <div className="relative overflow-x-auto shadow-md sm:rounded-lg w-full">
                                <div className="relative w-full h-full md:h-auto">
                                        
                                    {/* <form onSubmit={handleSubmit}  className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                        
                                        <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                                            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                                Add Student for Admission
                                            </h3>
                                            <NavLink to="/admin/about-us">
                                                <button className="bg-gray-500 w-[100px] rounded-md h-8 hover:bg-gray-400 text-center ">Back</button>
                                            </NavLink>
                                        </div>
                                        
                                        <div className="p-6 space-y-6">
                                            <div className="grid grid-cols-6 gap-6">
                                                <div className="col-span-6 sm:col-span-3">
                                                    <label htmlFor="user_id" className="block mb-2 text-sm font-bold text-gray-900 dark:text-white">Student Name :</label>
                                                    <select name="user_id" onChange={handleChange} id="user_id" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                                        <option selected disabled>--Select Student --</option>
                                                        {
                                                            users &&
                                                            users.filter((display)=> {
                                                                return display.role.match('student');
                                                            })
                                                            .map((user,index)=>(
                                                                <option key={index + 1} value={user._id}>{user.name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                    
                                                </div>                     
                                                <div className="col-span-6 sm:col-span-3">
                                                    <label htmlFor="forClass" className="block mb-2 text-sm font-bold text-gray-900 dark:text-white">Admission For :</label>
                                                    <select name="forClass" onChange={handleChange} id="forClass" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                                        <option selected disabled>--Select Class --</option>
                                                        {
                                                            classes &&
                                                            classes.map((clas,index)=>(
                                                                <option key={index + 1} value={clas._id}>{clas.name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                    
                                                </div> 
                                                <div className="col-span-6 sm:col-span-3">
                                                    <label htmlFor="rollNo" className="block mb-2 text-sm font-bold text-gray-900 dark:text-white">Roll Number :</label>
                                                    <input type="number" name="rollNo" id="rollNo" defaultValue={inputValue.rollNo || ''} onChange={handleChange} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Enter Student Class Roll Number..." />
                                                </div>                    
                                            </div>
                                        </div>
                                    
                                        <div className="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                                            <button type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Save all</button>
                                        </div>
                                    </form> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

