import React from 'react';
import { NavLink } from 'react-router-dom';

import Img from '../../../image/bgImage.jpg'
import Mem1 from '../../../image/new1.jpg'

export function MemberDetail(){

    return(
        <>
            <div className="max-w-full mx-auto">
               <div className="relative">
                <img className="h-[150px] lg:h-[200px] w-full object-cover" src={Img} alt="Img"></img>
                <div className="absolute justify-center items-center h-full left-0 top-[60px] lg:top-[100px]">
                    <div className="justify-center text-center text-0.5xl lg:text-xl py-10 lg:py-1 px-[150px] md:px-[250px] lg:px-[550px]">
                       <p className="text-yellow-400 font-bold text-2xl md:text-3xl uppercase"> Member 1</p> 
                    </div>
                </div>
               </div>
            </div>

            <div className="max-w-[1240px] mx-auto py-0 lg:py-10 px-20 lg:px-0">
                <div className="flex flex-col md:flex-row gap-x-5">
                    <div className="w-full md:w-2/3">
                        <p> With this new policy, a debit card will be required to book with me your card will NOT be charged for
                            booking! The reason for this new policy is due to an increased amount of no-shows and cancellations. Most 
                            of you know my NO SHOW/ CANCELLATION POLICY but just a friendly reminder if you need to cancel your appointment I
                            need to be notified 24 hours in advance so that I am able to fill your spot, if you do not let me know 24 hours in 
                            advance you will be CHARGED 50% of your service fees this policy will also be in effect if you are more than 10 minutes
                            late. If you are going to be more than 10 minutes late you will need to reschedule which means you will also be charged 
                            50% of your service fees! Please understand none of this is to be mean or rude but as I mentioned earlier I need everyone
                            to respect my time just as much as I respect all of yours! Thank you all for understanding.
                        </p>
                        <p className="pt-5"> With this new policy, a debit card will be required to book with me your card will NOT be charged for
                            booking! The reason for this new policy is due to an increased amount of no-shows and cancellations. Most 
                            of you know my NO SHOW/ CANCELLATION POLICY but just a friendly reminder if you need to cancel your appointment I
                            need to be notified 24 hours in advance so that I am able to fill your spot, if you do not let me know 24 hours in 
                            advance you will be CHARGED 50% of your service fees this policy will also be in effect if you are more than 10 minutes
                            late. If you are going to be more than 10 minutes late you will need to reschedule which means you will also be charged 
                            50% of your service fees! Please understand none of this is to be mean or rude but as I mentioned earlier I need everyone
                            to respect my time just as much as I respect all of yours! Thank you all for understanding.
                        </p>
                    </div>
                    <div className="w-full md:w-1/3 pt-5 md:pt-0 flex flex-col justify-center items-center">
                        <img className="h-full w-full object-cover" src={Mem1} alt="Img" />
                        <NavLink to="/online-booking" className="w-full"><button className="py-2 w-full justify-center text-center uppercase bg-black  px-10 rounded-lg font-semibold
                        text-white hover:bg-gray-400 hover:text-black transition duration-300">Book Online</button></NavLink>
                    </div>
                </div> 
            </div>

            <div className="max-w-[1240px] mx-auto px-20 lg:px-0 py-10">
                <div class="inline-flex items-center justify-center  w-full">
                    <hr class="w-full h-px my-8 bg-orange-500 border-0 dark:bg-orange-700" />
                    <span class="absolute px-3 font-bold text-orange-900 -translate-x-1/2 bg-white left-1/2 dark:text-white dark:bg-gray-900 uppercase">MICROBLEDING</span>
                </div>
                <div className="flex flex-col justify-center text-center">
                    <div className="font-bold text-gray-600">
                        <p>What is Microblading?</p>
                    </div>
                    <div className="text-gray-400">
                        <p> With this new policy, a debit card will be required to book with me your card will NOT be charged for
                            booking! The reason for this new policy is due to an increased amount of no-shows and cancellations. Most 
                            of you know my NO SHOW/ CANCELLATION POLICY but just a friendly reminder if you need to cancel your appointment I
                            need to be notified 24 hours in advance so that I am able to fill your spot, if you do not let me know 24 hours in 
                            advance you will be CHARGED 50% of your service fees this policy will also be in effect if you are more than 10 minutes
                            late. If you are going to be more than 10 minutes late you will need to reschedule which means you will also be charged 
                            50% of your service fees! Please understand none of this is to be mean or rude but as I mentioned earlier I need everyone
                            to respect my time just as much as I respect all of yours! Thank you all for understanding.
                        </p>
                    </div>
                </div>
                <div className="flex flex-col justify-center text-center pt-10">
                    <div className="font-bold text-gray-600 underline">
                        <p>Microbleding Brows Pricing</p>
                    </div>
                    <div className="font-bold text-gray-600 pt-10">
                        <p>Microblading Brows</p>
                    </div>
                    <div className="text-gray-400">
                        <p> With this new policy, a debit card will be required to book with me your card will NOT be charged for
                            booking! The reason for this new policy is due to an increased amount of no-shows and cancellations. Most 
                            of you know my NO SHOW/ CANCELLATION POLICY but just a friendly reminder if you need to cancel your appointment I
                            need to be notified 24 hours in advance so that I am able to fill your spot, if you do not let me know 24 hours in 
                           
                        </p>
                    </div>
                    <div className="font-bold text-gray-600 pt-10">
                        <p>Microblading and Shading Brows Pricing</p>
                    </div>
                    <div className="text-gray-400">
                        <p> With this new policy, a debit card will be required to book with me your card will NOT be charged for
                            booking! The reason for this new policy is due to an increased amount of no-shows and cancellations. Most 
                            of you know my NO SHOW/ CANCELLATION POLICY but just a friendly reminder if you need to cancel your appointment I
                            need to be notified 24 hours in advance so that I am able to fill your spot, if you do not let me know 24 hours in 
                           
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}