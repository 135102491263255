import React from 'react'

import Img from '../../../image/bgImage.jpg'
import set1 from '../../../image/se1.jpg'
import set10 from '../../../image/SE_10.jpg'
import set11 from '../../../image/SE_11.jpg'
import set12 from '../../../image/SE_12.jpg'
import set13 from '../../../image/SE_13.jpg'

export function Gallery(){

    return(
        <>
             <div className="max-w-full mx-auto pb-5">
               <div className="relative">
                <img className="h-[150px] md:h-[200px] w-full object-cover" src={Img} alt="Img"></img>
                <div className="absolute justify-center items-center h-full left-0 top-[60px] lg:top-[100px]">
                    <div className="justify-center text-center text-0.5xl lg:text-xl py-10 lg:py-1 px-[180px] md:px-[300px] lg:px-[600px]">
                       <p className="text-yellow-400 font-bold text-2xl md:text-3xl uppercase"> Gallery</p> 
                    </div>
                </div>
               </div>
            </div>

            <div className="max-w-[1240px] mx-auto px-4 pb-5 lg:py-10 grid sm:grid-cols-2 lg:grid-cols-4 gap-4 ">
                <div className="relative">
                    <img className="h-full w-full object-cover" src={set1} alt="set1"></img>
                    <div className="absolute bg-gray-900/10 top-0 left-0 h-full w-full">
                        <p className="left-4 bottom-4 text-2xl text-white font-bold absolute"></p>
                    </div>
                </div>
                <div className="relative">
                    <img className="h-full w-full object-cover" src={set10} alt="set10"></img>
                    <div className="absolute bg-gray-900/10 top-0 left-0 h-full w-full">
                        <p className="left-4 bottom-4 text-xl text-white font-bold absolute"></p>
                    </div>
                </div>
                <div className="relative">
                    <img className="h-full w-full object-cover" src={set11} alt="set11"></img>
                    <div className="absolute bg-gray-900/10 top-0 left-0 h-full w-full">
                        <p className="left-4 bottom-4 text-xl text-white font-bold absolute"></p>
                    </div>
                </div>
                <div className="relative">
                    <img className="h-full w-full object-cover" src={set12} alt="set12"></img>
                    <div className="absolute bg-gray-900/10 top-0 left-0 h-full w-full">
                        <p className="left-4 bottom-4 text-xl text-white font-bold absolute"></p>
                    </div>
                </div>
                <div className="relative">
                    <img className="h-full w-full object-cover" src={set13} alt="set13"></img>
                    <div className="absolute bg-gray-900/10 top-0 left-0 h-full w-full">
                        <p className="left-4 bottom-4 text-xl text-white font-bold absolute"></p>
                    </div>
                </div>
                <div className="relative">
                    <img className="h-full w-full object-cover" src={set10} alt="set10"></img>
                    <div className="absolute bg-gray-900/10 top-0 left-0 h-full w-full">
                        <p className="left-4 bottom-4 text-xl text-white font-bold absolute"></p>
                    </div>
                </div>
            </div>
        </>
    )
}