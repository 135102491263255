import React from 'react';

export function GoogleMap(){

    return(
        <>
            <div className="max-w-full h-[400px]">
                <iframe title="Location Map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13068.849839816261!2d117.87389865030663!3d-35.026414443352394!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2a392725e23b9d29%3A0x500f638247a0e90!2sAlbany%20WA%206330%2C%20Australia!5e0!3m2!1sen!2snp!4v1691145501705!5m2!1sen!2snp" className="w-full h-[400px]"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </>
    )
}