import React from 'react'
import {FaStar} from 'react-icons/fa'

export function CustomerReview(){

    return(
        <>
            <div classname="bg-white max-w-full mx-auto">
                <div className="text-3xl text-orange-700 justify-center items-center text-center">
                    <p className="pt-32 font-mono">Our Customer's Feedback</p>
                    <div className="inline-flex items-center justify-center w-full">
                        <hr className="w-64 h-1 my-8 bg-gray-200 border-0 rounded dark:bg-orange-700" />
                        <div className="absolute px-4 -translate-x-1/2 bg-white left-1/2 dark:bg-gray-900">
                            <svg className="w-4 h-4 text-orange-700 dark:text-gray-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 14">
                                <path d="M6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3H2a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Zm10 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3h-1a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Z"/>
                            </svg>
                        </div>
                    </div>
               </div>
                <div className="max-w-[1240px] mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 py-10 gap-x-2 gap-y-4 px-3 lg:px-0">
                    <div className="flex flex-col p-4 bg-gray-400 border shadow-lg gap-y-3 rounded-tl-3xl rounded-br-3xl">
                        <div className="text-2xl font-semibold text-orange-600">Beauty Parlor</div>
                            <div className="text-xl flex flex-row items-center ">
                                <p>Rating :</p>
                                <p className="flex"><FaStar /> <FaStar /> <FaStar /></p>
                            </div>
                            <div className="text-0.5 font-light">
                                <p>As you all know I am currently working by myself, I am booked 
                                    in advance (Depending on service)meaning all my appointments are back to back! That being said, while
                                    I do my best to try and accommodate everyone, my time is very valuable so to respect my time, and yours</p>
                            </div>
                    </div>
                    <div className="flex flex-col p-4 bg-gray-400 border shadow-lg gap-y-3 rounded-tl-3xl rounded-br-3xl">
                        <div className="text-2xl font-semibold text-orange-600">Beauty Parlor</div>
                        <div className="text-xl flex flex-row items-center ">
                            <p>Rating :</p>
                            <p className="flex"><FaStar /> <FaStar /> <FaStar /></p>
                        </div>
                        <div className="text-0.5 font-light">
                            <p>As you all know I am currently working by myself, I am booked 
                                in advance (Depending on service)meaning all my appointments are back to back! That being said, while
                                I do my best to try and accommodate everyone, my time is very valuable so to respect my time, and yours</p>
                        </div>
                    </div>
                    <div className="flex flex-col p-4 bg-gray-400 border shadow-lg gap-y-3 rounded-tl-3xl rounded-br-3xl">
                        <div className="text-2xl font-semibold text-orange-600">Beauty Parlor</div>
                        <div className="text-xl flex flex-row items-center ">
                            <p>Rating :</p>
                            <p className="flex"><FaStar /> <FaStar /> <FaStar /></p>
                        </div>
                        <div className="text-0.5 font-light">
                            <p>As you all know I am currently working by myself, I am booked 
                                in advance (Depending on service)meaning all my appointments are back to back! That being said, while
                                I do my best to try and accommodate everyone, my time is very valuable so to respect my time, and yours</p>
                        </div>
                    </div>
                    <div className="flex flex-col p-4 bg-gray-400 border shadow-lg gap-y-3 rounded-tl-3xl rounded-br-3xl">
                        <div className="text-2xl font-semibold text-orange-600">Beauty Parlor</div>
                        <div className="text-xl flex flex-row items-center ">
                            <p>Rating :</p>
                            <p className="flex"><FaStar /> <FaStar /> <FaStar /></p>
                        </div>
                        <div className="text-0.5 font-light">
                            <p>As you all know I am currently working by myself, I am booked 
                                in advance (Depending on service)meaning all my appointments are back to back! That being said, while
                                I do my best to try and accommodate everyone, my time is very valuable so to respect my time, and yours</p>
                        </div>
                    </div>
                    <div className="flex flex-col p-4 bg-gray-400 border shadow-lg gap-y-3 rounded-tl-3xl rounded-br-3xl">
                        <div className="text-2xl font-semibold text-orange-600">Beauty Parlor</div>
                        <div className="text-xl flex flex-row items-center ">
                            <p>Rating :</p>
                            <p className="flex"><FaStar /> <FaStar /> <FaStar /></p>
                        </div>
                        <div className="text-0.5 font-light">
                            <p>As you all know I am currently working by myself, I am booked 
                                in advance (Depending on service)meaning all my appointments are back to back! That being said, while
                                I do my best to try and accommodate everyone, my time is very valuable so to respect my time, and yours</p>
                        </div>
                    </div>  
                </div>
            </div>
        </>
    )
}