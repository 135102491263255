import React from 'react';
import { NavLink } from 'react-router-dom';

import Img from '../../../image/bgImage.jpg'
import Hair from '../../../image/hair_new.jpg'
import Makeup from '../../../image/makeup_new.jpg'
import Nails from '../../../image/nails_new-1.jpg'
import Spa from '../../../image/spa_new.jpg'
import set1 from '../../../image/se1.jpg'


export function Services(){

    return(
        <>
            <div className="max-w-full mx-auto">
               <div className="relative">
                <img className="h-[150px] md:h-[200px] w-full object-cover" src={Img} alt="Img"></img>
                <div className="absolute justify-center items-center h-full left-0 top-[60px] lg:top-[100px]">
                    <div className="justify-center text-center text-0.5xl lg:text-xl py-10 lg:py-1 px-[150px] md:px-[250px] lg:px-[550px]">
                       <p className="text-yellow-400 font-bold text-2xl md:text-3xl uppercase"> Services</p> 
                    </div>
                </div>
               </div>
            </div>

            <div className="max-w-[1240px] mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 gap-x-5 pb-5 lg:py-10 p-5 lg:px-0">
                <div className="bg-gray-600 flex flex-col p-10">
                    <div className="">
                        <p className="font-semibold uppercase text-white text-3xl">How to book your appointment</p>
                    </div>
                    <div className="flex flex-col pt-7 ">
                        <div className="flex gap-x-1 py-3">
                            <p className="text-2xl text-yellow-400 font-bold">1.</p>
                            <p className="text-white pt-2">Choose your service.</p>
                        </div>
                        <div className="flex gap-x-1 py-3">
                            <p className="text-2xl text-yellow-400 font-bold">2.</p>
                            <p className="text-white pt-2">Brouse the profiles of our Professional team.</p>
                        </div>
                        <div className="flex gap-x-1 py-3">
                            <p className="text-2xl text-yellow-400 font-bold">3.</p>
                            <p className="text-white pt-2">Once you've found professional(s) that can 
                             service your needs, simply follow the Book Online link on their page to
                             schedule an appointment. If your preferred professional does not have a 
                             booking option, text or call directly to the phone number listed on their 
                             page.
                             </p>
                        </div>
                       
                      
                    </div>
                </div>
                <div className="bg-gray-200 flex flex-col ">
                    <div className="p-5">
                        <img className="h-[400px] w-full object-cover" src={Hair} alt="Img" />
                    </div>
                    <div className="flex flex-col p-3 justify-center text-center">
                        <p className="text-xl font-semibold uppercase pb-5">Hair</p>
                        <NavLink to="/services/hair"><button className="py-2  justify-center text-center uppercase bg-orange-800  px-10 rounded-lg font-semibold
                            text-white hover:bg-gray-600 hover:text-black transition duration-300">Find A Professional</button>
                        </NavLink>
                    </div>
                </div>
                <div className="bg-gray-200 flex flex-col ">
                    <div className="p-5">
                        <img className="h-[400px] w-full object-cover" src={Makeup} alt="Img" />
                    </div>
                    <div className="flex flex-col p-3 justify-center text-center">
                        <p className="text-xl font-semibold uppercase pb-5">Make-up</p>
                        <NavLink to="/services/hair"><button className="py-2 justify-center text-center uppercase bg-orange-800  px-10 rounded-lg font-semibold
                            text-white hover:bg-gray-600 hover:text-black transition duration-300">Find A Professional</button>
                        </NavLink>
                    </div>
                </div>
                <div className="bg-gray-200 flex flex-col ">
                    <div className="p-5">
                        <img className="h-[400px] w-full object-cover" src={Nails} alt="Img" />
                    </div>
                    <div className="flex flex-col p-3 justify-center text-center">
                        <p className="text-xl font-semibold uppercase pb-5">Nails</p>
                        <NavLink to="/serices/hair"><button className="py-2 justify-center text-center uppercase bg-orange-800  px-10 rounded-lg font-semibold
                            text-white hover:bg-gray-600 hover:text-black transition duration-300">Find A Professional</button>
                        </NavLink>
                    </div>
                </div>
                <div className="bg-gray-200 flex flex-col ">
                    <div className="p-5">
                        <img className="h-[400px] w-full object-cover" src={Spa} alt="Img" />
                    </div>
                    <div className="flex flex-col p-3 justify-center text-center">
                        <p className="text-xl font-semibold uppercase pb-5">Spa</p>
                        <NavLink to="/services/hair"><button className="py-2 justify-center text-center uppercase bg-orange-800  px-10 rounded-lg font-semibold
                            text-white hover:bg-gray-600 hover:text-black transition duration-300">Find A Professional</button>
                        </NavLink>
                    </div>
                </div>
                <div className="bg-gray-200 flex flex-col ">
                    <div className="p-5">
                        <img className="h-[400px] w-full object-cover" src={set1} alt="Img" />
                    </div>
                    <div className="flex flex-col p-3 justify-center text-center">
                        <p className="text-xl font-semibold uppercase pb-5"></p>
                        <NavLink to="/gallery"><button className="py-2 justify-center text-center uppercase bg-orange-400  px-10 rounded-lg font-semibold
                            text-white hover:bg-gray-600 hover:text-black transition duration-300">View our gallery</button>
                        </NavLink>
                    </div>
                </div>
            </div>
        </>
    )
}