import React from 'react';
import { Banner } from '../front/banner/banner';
import { AboutUs } from '../front/about/about';
import { ServiceOffer } from '../front/service-offer/service-list';
import { MenuList } from '../front/menu-list/menu-list';
import { BusinessPolicy } from '../front/business-policy/business-policy';
import { ContactUs } from '../front/contactus/contactus';
import { GoogleReview } from '../front/google-review/google-review';
import { Details } from '../front/details/details';
import { GoogleMap } from '../front/googlemap/googlemap';
import { HomeSlider } from '../front/home-slider/home-slider';


export function Home(){

    return(
        <>
            <Banner />
            <AboutUs />
            <HomeSlider />
            <ServiceOffer />
            {/* <MenuList /> */}
            <BusinessPolicy />
            <ContactUs />
            {/* <GoogleReview /> */}
            <Details />
            <GoogleMap />
            
        </>
    )
}