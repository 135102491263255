import React from 'react';

import Img from '../../../image/bgImage.jpg'
import { ImageSlider } from './slider';

import Female from '../../../image/female.jpg'
import Brushkit from '../../../image/brushkit.jpg'

export function SingleAboutPage(){

    return(
        <>
            <div className="max-w-full mx-auto">
               <div className="relative">
                <img className="h-[150px] md:h-[200px] w-full object-cover" src={Img} alt="Img"></img>
                <div className="absolute justify-center items-center text-center h-full left-0 top-[80px] lg:top-[100px]">
                    <div className="justify-center text-center py-5 px-[100px] lg:px-[500px]">
                       <p className="text-yellow-300 font-bold text-2xl md:text-3xl uppercase">
                         About Our Parlor
                       </p>
                    </div>
                </div>
               </div>
            </div>

            <div className="max-w-[1240px] mx-auto bg-white py-1 md:py-5">
                <div className="justify-center text-center font-bold text-xl lg:text-2xl body-font font-tangerine">
                    <p>We guarentee the best services at Ritaz Parlor</p>
                </div>
                <div className="py-5 flex flex-col lg:flex-row gap-5">
                    <div>
                        <img src={Female} className="w-full h-full object-cover" alt="Img" />
                    </div>
                    <div  className="px-5">
                        <p>
                            Rita, the Founder and Principal Operator, is thrilled to declare the grand 
                            inauguration of Ritaz Parlor. Our salon exudes an atmosphere of utmost 
                            refinement and sophistication. As our enterprise has progressed, so has our 
                            unwavering dedication to excellence in service. Our vocation's ultimate 
                            aspiration is to deliver the pinnacle of artistic mastery. Our actions align 
                            with our convictions.
                        </p>
                        <p>
                            Rita, our esteemed professional, holds Esthetician licenses granted by the 
                            Ohio State Board of Cosmetology, boasting an impressive career spanning over 
                            years.
                        </p>
                    </div>
                </div>
            </div>

            <div className="max-w-full mx-auto py-1 md:py-5 bg-gray-100">
               <div className="max-w-[1240px] mx-auto">
                <div className="py-5 flex flex-col lg:flex-row gap-5">
                        <div>
                            <img src={Brushkit} className="w-full h-full object-cover" alt="Img" />
                        </div>
                        <div  className="px-5">
                            <p>
                                Rita, the Founder and Principal Operator, is thrilled to declare the grand inauguration of Ritaz Parlor. Our salon exudes an atmosphere of utmost refinement and sophistication. As our enterprise has progressed, so has our unwavering dedication to excellence in service. Our vocation's ultimate aspiration is to deliver the pinnacle of artistic mastery. Our actions align with our convictions.
                            </p>
                            <p>
                                Rita, our esteemed professional, holds Esthetician licenses granted by the Ohio State Board of Cosmetology, boasting an impressive career spanning over years.
                            </p>
                        </div>
                    </div>
               </div>
            </div>
             
             

            {/* <div className="max-w-full bg-white mx-auto py-1 md:py-5">
                <div className="text-left lg:justify-center lg:text-center px-[15px] lg:px-400px">
                    <p  className="text-left lg:justify-center lg:text-center px-[40px] lg:px-400px">
                        With this new policy, a debit card will be required to book with me your card will NOT be charged for
                        booking! The reason for this new policy is due to an increased amount of no-shows and cancellations. Most 
                        of you know my NO SHOW/ CANCELLATION POLICY but just a friendly reminder if you need to cancel your appointment I
                        need to be notified 24 hours in advance so that I am able to fill your spot, if you do not let me know 24 hours in 
                        advance you will be CHARGED 50% of your service fees this policy will also be in effect if you are more than 10 minutes
                        late. If you are going to be more than 10 minutes late you will need to reschedule which means you will also be charged 
                        50% of your service fees! Please understand none of this is to be mean 
                    </p>
                </div>
            </div> */}

            {/* <ImageSlider /> */}

            {/* <div className="max-w-[1240px] mx-auto py-10">
                <div className="flex flex-col lg:flex-row gap-x-5 px-5 lg:px-0">
                    <div className="w-full lg:w-1/3">
                        <button className="py-2 w-full h-[60px] justify-center text-center uppercase bg-yellow-300  px-10 rounded-lg font-bold text-3xl
                        text-white hover:bg-gray-400 hover:text-black transition duration-300">Our Mission</button>
                    </div>
                        
                    <div className="w-full lg:w-2/3">
                        <p> With this new policy, a debit card will be required to book with me your card will NOT be charged for
                            booking! The reason for this new policy is due to an increased amount of no-shows and cancellations. Most 
                            of you know my NO SHOW/ CANCELLATION POLICY but just a friendly reminder if you need to cancel your appointment I
                            need to be notified 24 hours in advance so that I am able to fill your spot, if you do not let me know 24 hours in 
                            advance you will be CHARGED 50% of your service fees this policy will also be in effect if you are more than 10 minutes
                            late. If you are going to be more than 10 minutes late you will need to reschedule which means you will also be charged 
                            50% of your service fees! Please understand none of this is to be mean or rude but as I mentioned earlier I need everyone
                            to respect my time just as much as I respect all of yours! Thank you all for understanding.
                        </p>
                    </div>
                </div> 
            </div>  */}

            {/* <div className="max-w-[1240px] mx-auto py-10">
                <div className="flex flex-col lg:flex-row gap-x-5 px-5 lg:px-0">
                    <div className="w-full lg:w-1/3">
                        <button className="py-2 w-full h-[60px] justify-center text-center uppercase bg-yellow-300  px-10 rounded-lg font-bold text-3xl
                        text-white hover:bg-gray-400 hover:text-black transition duration-300">Our Philosophy</button>
                    </div>
                        
                    <div className="w-full lg:w-2/3">
                        <p> With this new policy, a debit card will be required to book with me your card will NOT be charged for
                            booking! The reason for this new policy is due to an increased amount of no-shows and cancellations. Most 
                            of you know my NO SHOW/ CANCELLATION POLICY but just a friendly reminder if you need to cancel your appointment I
                            need to be notified 24 hours in advance so that I am able to fill your spot, if you do not let me know 24 hours in 
                            advance you will be CHARGED 50% of your service fees this policy will also be in effect if you are more than 10 minutes
                            late. If you are going to be more than 10 minutes late you will need to reschedule which means you will also be charged 
                            50% of your service fees! Please understand none of this is to be mean or rude but as I mentioned earlier I need everyone
                            to respect my time just as much as I respect all of yours! Thank you all for understanding.
                        </p>
                    </div>
                </div> 
            </div>  */}
        </>
    )
}