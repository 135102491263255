import React from 'react';

import Img from '../../../image/bgImage.jpg'
import Feature from '../../../image/studiofeatures_02.png'
import Profit from '../../../image/profit.jpg'

export function OwnAStudio(){

    return(
        <>
             <div className="max-w-full mx-auto">
               <div className="relative">
                <img className="h-[150px] md:h-[200px] w-full object-cover" src={Img} alt="Img"></img>
                <div className="absolute justify-center items-center h-full left-0 top-[60px] lg:top-[100px]">
                    <div className="justify-center text-center text-0.5xl lg:text-xl py-10 lg:py-1 px-[150px] lg:px-[550px]">
                       <p className="text-yellow-400 font-bold text-2xl md:text-3xl uppercase"> Own A Studio</p> 
                    </div>
                </div>
               </div>
            </div>

            <div className="max-w-full mx-auto bg-white pb-10">
                <div className="max-w-[1240px] mx-auto">
                    <div className="justify-center text-center py-5">
                        <p className="font-bold uppercase text-xl lg:text-4xl text-yellow-400"> Reasons To Own</p>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-10">
                       
                        <div className="w-full h-full">
                            <img src={Feature} className="w-full h-full object-cover" alt="Img" />
                        </div>
                        <div className="mx-5 lg:mx-0 mt-5 lg:mt-0">
                            <p>
                                With this new policy, a debit card will be required to book with me your card will NOT be charged for
                                booking! The reason for this new policy is due to an increased amount of no-shows and cancellations. Most 
                                of you know my NO SHOW/ CANCELLATION POLICY but just a friendly reminder if you need to cancel your appointment I
                                need to be notified 24 hours in advance so that I am able to fill your spot, if you do not let me know 24 hours in 
                                advance you will be CHARGED 50% of your service fees this policy will also be in effect if you are more than 10 minutes
                                late. If you are going to be more than 10 minutes late you will need to reschedule which means you will also be charged 
                                50% of your service fees! Please understand none of this is to be mean or rude but as I mentioned earlier I need everyone
                                to respect my time just as much as I respect all of yours! Thank you all for understanding.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="max-w-full mx-auto bg-gray-300 py-10">
                <div className="max-w-[1240px] mx-auto">
                    <div className="justify-center text-center py-1 lg:py-5">
                        <p className="font-bold uppercase text-xl lg:text-4xl text-yellow-400"> Studio Features</p>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-10">
                    <div className="mx-5 lg:mx-0">
                            <p>
                                With this new policy, a debit card will be required to book with me your card will NOT be charged for
                                booking! The reason for this new policy is due to an increased amount of no-shows and cancellations. Most 
                                of you know my NO SHOW/ CANCELLATION POLICY but just a friendly reminder if you need to cancel your appointment I
                                need to be notified 24 hours in advance so that I am able to fill your spot, if you do not let me know 24 hours in 
                                advance you will be CHARGED 50% of your service fees this policy will also be in effect if you are more than 10 minutes
                                late. If you are going to be more than 10 minutes late you will need to reschedule which means you will also be charged 
                                50% of your service fees! Please understand none of this is to be mean or rude but as I mentioned earlier I need everyone
                                to respect my time just as much as I respect all of yours! Thank you all for understanding.
                            </p>
                        </div>
                        <div className="w-full h-full mt-5 lg:mt-0">
                            <img src={Feature} className="w-full h-full object-cover" alt="Img" />
                        </div>
                       
                    </div>
                </div>
            </div>

            <div className="max-w-full mx-auto bg-white py-10">
                <div className="max-w-[1240px] mx-auto">
                    <div className="justify-center text-center py-5">
                        <p className="font-bold uppercase text-xl lg:text-4xl text-yellow-400"> Profit</p>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-10">
                       
                        <div className="w-full h-full">
                            <img src={Profit} className="w-full h-full object-cover" alt="Img" />
                        </div>
                        <div className="mx-5 lg:mx-0 mt-5 lg:mt-0">
                            <p>
                                With this new policy, a debit card will be required to book with me your card will NOT be charged for
                                booking! The reason for this new policy is due to an increased amount of no-shows and cancellations. Most 
                                of you know my NO SHOW/ CANCELLATION POLICY but just a friendly reminder if you need to cancel your appointment I
                                need to be notified 24 hours in advance so that I am able to fill your spot, if you do not let me know 24 hours in 
                                advance you will be CHARGED 50% of your service fees this policy will also be in effect if you are more than 10 minutes
                                late. If you are going to be more than 10 minutes late you will need to reschedule which means you will also be charged 
                                50% of your service fees! Please understand none of this is to be mean or rude but as I mentioned earlier I need everyone
                                to respect my time just as much as I respect all of yours! Thank you all for understanding.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}