import React from 'react';
import { NavLink } from 'react-router-dom';

import {FaRegEdit} from 'react-icons/fa';

export function ViewAboutUs(){

   

    return(
        <div className="flex flex-col p-10">
            <div className="flex justify-between items-center">
                <p className="text-2xl text-semibold">Dashboard</p>
                <p>Home/View Details</p>
            </div>
            <div className="pt-10">
                <div className="bg-gray-200 px-5 drop-shadow-lg rounded-lg">
                    <div className="py-2">
                        <div className="flex justify-between items-center py-3">
                            <p className="text-2xl font-medium">About Us</p>
                            <NavLink to='/admin/about-us'>
                                <button className="bg-gray-500 w-[100px] rounded-md h-8 hover:bg-gray-400 text-center ">Back</button>
                            </NavLink>
                        </div>

                        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                <thead className="text-xs text-gray-700 uppercase bg-gray-400 dark:bg-gray-700 dark:text-gray-400">
                                    <tr>
                                        <th scope="col" className="px-6 py-3">
                                          Weekday
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            First Period
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Second Period
                                        </th>                                    
                                        
                                    </tr>
                                </thead>
                                <tbody>
                                    
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}