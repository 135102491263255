import React from 'react';

export function AboutUs(){

    return(
        <>
            <div className="max-w-[1024px] mx-auto bg-white py-5 lg:py-12 px-10 lg:px-0">
               <div className="flex flex-col">
                    <div className="flex flex-col">
                        <div className="text-center text-3xl text-orange-700"> Welcome</div>
                        <div className="text-semibold pt-6 justify-center text-center items-center">
                            <p>
                                Welcome to Ritaz Parlor Heath OH, where beauty meets precision and perfection. Our dedicated team of skilled professionals is here to enhance your natural beauty and boost your confidence through a variety of specialized services.

                                At Ritaz Parlor, we understand that your eyebrows frame your face and your lashes can speak volumes. That's why we offer expert eyebrow threading and tinting services to sculpt and define your brows, accentuating your unique features. Our experienced aestheticians will work with you to achieve the perfect shape and shade that complements your individual style.

                                For silky-smooth, hair-free skin, indulge in our waxing services. From brows to legs and everywhere in between, our gentle and effective waxing techniques will leave your skin feeling soft and radiant.

                            </p>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 pt-10">
                        <div className="py-6 px-4">
                            <div className="text-center text-xl text-orange-700"> History of Threading</div>
                            <div className="text-semibold pt-6 justify-center text-center items-center">
                                <p>
                                    Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos 
                                    de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias
                                     desde el año 1500, cuando un impresor (N. del T. persona que se dedica a la
                                      imprenta) desconocido usó una galería de textos y los mezcló de tal manera
                                       que logró hacer un libro de textos especimen.
                                </p>
                            </div>
                        </div>
                        <div className="py-6 px-4">
                            <div className="text-center text-xl text-orange-700"> Accuracy</div>
                            <div className="text-semibold pt-6 justify-center text-center items-center">
                                <p>
                                    Lorem Ipsum es simplemente el texto de relleno de las imprentas y archivos 
                                    de texto. Lorem Ipsum ha sido el texto de relleno estándar de las industrias
                                     desde el año 1500, cuando un impresor (N. del T. persona que se dedica a la
                                      imprenta) desconocido usó una galería de textos y los mezcló de tal 
                                       que logró hacer un libro de textos especimen.
                                </p>
                            </div>
                        </div>
                    </div>
               </div>
            </div>
        </>
    )
}