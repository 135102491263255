import React from 'react';

import Img from '../../../image/bgImage.jpg'
import { NavLink } from 'react-router-dom';

export function Login(){

    return(
        <>
            <div className="w-screen mx-auto pb-5">
               <div className="relative">
                <img className="h-[100px] lg:h-[150px] w-full object-cover" src={Img} alt="Img"></img>
                <div className="absolute justify-center items-center h-full left-0 top-0 lg:top-[50px]">
                    <div className="justify-center text-center text-0.5xl lg:text-xl py-10 lg:py-1 px-[120px] md:px-[250px] lg:px-[550px]">
                       <p className="text-yellow-400 font-bold text-2xl md:text-3xl uppercase"> Login</p> 
                    </div>
                </div>
               </div>
            </div>

            <div className="max-w-screen-md mx-auto justify-center items-center px-5 lg:px-0 py-5">
                <div className="bg-gray-300 border shadow-lg border-1 py-5 px-8 lg:px-40 rounded-xl">
                    <form >
                        <div className="flex flex-col w-full">
                            <label className="py-2 font-semibold">Email *:</label>
                            <input type="email" name="email"  defaultValue='' placeholder="Enter Email" className="h-[40px] rounded-lg px-3 drop-shadow-lg w-full border" />
                            <span className="text-danger"></span>
                        </div>
                        <div className="flex flex-col w-full">
                            <label className="py-2 font-semibold">Password *:</label>
                            <input type="password" name="password" defaultValue='' placeholder="XXXXXXXX" className="h-[40px] rounded-lg px-3 drop-shadow-lg w-full border" />
                            <span className="text-danger"></span>
                        </div>
                        <div className="flex py-5 justify-center items-center">
                            <button type="submit" className="bg-gray-400 py-2  px-10 rounded-lg font-semibold
                            text-white hover:bg-gray-500 hover:text-black transition duration-300">Login</button>
                        </div>
                    </form>
                    <div className="flex py-5 justify-between items-center">
                           <p className="font-normal"><NavLink to="">Forget Password?</NavLink></p>
                           <p>Don't have an account? <NavLink to="/register" className="text-blue-800 underline">Sign Up</NavLink></p>
                        </div>
                </div>
            </div>

            
        </>
    )
}